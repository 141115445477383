import React, { Component, Fragment } from 'react'
//import ReactDOM from 'react-dom'
import {Play, SkipBack, SkipForward, Film, Maximize, VolumeX, Rewind, FastForward} from 'react-feather'

import './VideoComponent.css'

class VideoComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: false,
      mobileWidth: false
    }

    this._isMounted = false;

    this.skipButtons = [
        { name: "Skip Backwards", element: <Rewind />, cn: 'skip sbw', label: '-1s', val: -1 },
        { name: "Skip Forwards", element: <FastForward />, cn: 'skip sfw', label: '+1s', val: 1}
    ]
    this.frameButtons = [
        { name: "Frame Backwards", element: <SkipBack />, cn: 'frame fbw', label: '-1f', val: -1 },
        { name: "Frame Forwards", element: <SkipForward />, cn: 'frame sfw', label: '+1f', val:1 }   
    ]
  }
  
  componentDidMount() {
    this._isMounted = true;
    this.updateDimensions()
    window.addEventListener('resize', () => this.updateDimensions())
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions() {
    if(this._isMounted)this.setState({ mobileWidth: window.innerWidth <= 900 })
  }

  render() {
    const { className, src } = this.props
    return (
      <Fragment>
        <div className="player">
          <Film className="film-icon"/>
          <video
            ref={this.video}
            className={`${className} player__video viewer`}
            playsInline
            muted
            loop
            preload="auto">
            <source src={`${src}`}/>
          </video>
          <div className="player__controls">
            <div className="progress">
              <div className="progress__filled"></div>
            </div>
            <div className="controls">
              <div className="player__controls-left">          
                <button className="player__button play" title="Toggle Play"><Play /></button>
                <button className="player__button volume" title="Toggle Volume"><VolumeX /></button>
                <input type="range" name="volume" className="player__slider" min="0" max="1" step="0.05" defaultValue="0"/>
                <div className="time-display">
                  <span className="time-current"></span>
                  <span className="ytp-time-separator"> / </span>
                  <span className="time-duration"></span>
                </div>
                {this.skipButtons.map((btn, i) => (              
                  <button className={`player__button ${btn.cn}`} key={i} title={btn.label} value={btn.value}>{btn.element}</button>
                ))}
                <div className="player__button-container" > 
                  {this.frameButtons.map((btn, i) => (               
                    <button className={`player__button ${btn.cn}`} key={i} title={btn.label} value={btn.value}>{btn.element}</button>
                  ))}
                </div>
              </div>
              <div className="player__controls-right">
                <button className="player__button fullscreen"><Maximize className="fullscreen-icon" /></button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default VideoComponent
