import React from 'react'
import _ from 'lodash'
import { Link, graphql } from 'gatsby'
import { ArrowLeft, ArrowRight   } from 'react-feather'
import {isMobile} from 'react-device-detect'

import Content from '../components/Content2'
import Layout from '../components/Layout'
import CanvasComponent from '../components/CanvasComponent'

import './SinglePost.css'

export const SinglePostTemplate = ({
  title,
  thumbNail,
  model,
  date,
  body,
  nextPostURL,
  prevPostURL,
  category,
  gumroad,
  artstation,
  locale
}) => (
  <main>
    <Link className="SinglePost--BackButton circleButton right" to={`/${locale}/works/${_.camelCase(category)}`}>
      <span className="txt">Back to Overview</span>
          <span className="circle"></span>
          <ArrowLeft size='15'/>
    </Link>
    <article className="SinglePost section">     
      <div className="SinglePost--Content relative">
        <div className="title__container">
          <h2 className="SinglePost--Title">{title}</h2>
            {category && <p className="Single">{category}</p>}
          </div>
         {model && !isMobile && (
           <CanvasComponent model={model} poster={thumbNail} title={title}></CanvasComponent>
          )}

        <div className="SinglePost--InnerContent">
          <Content source={body} />
          <div className="sales_btns">
          {gumroad !== '' && (
            <a className="sale_btn gumroad-button" href={`https://gum.co/${gumroad}`}>
              <span className="gumroad-button-logo"></span>
              <span>Get the {title} here</span>
            </a>
          )}
          {artstation !== '' && (
            <a className="sale_btn artstation-button" href={`${artstation}`} target="_blank">
              <span className="artstation-button-logo"></span>
              <span>Get the {title} here</span>
            </a>
          )}
          </div>
        </div>

        <div className="SinglePost--Pagination">
          {prevPostURL && (
            <Link className="SinglePost--Pagination--Link prev circleButton right" to={prevPostURL}>
              <span className="txt">Previous Post</span>
              <span className="circle"></span>
              <ArrowLeft size='15'/>
            </Link>
          )}
          {nextPostURL && (
            <Link className="SinglePost--Pagination--Link next circleButton left" to={nextPostURL}>
              <span className="txt">Next Post</span>
              <span className="circle"></span>
              <ArrowRight size='15'/>
            </Link>
          )}
        </div>
      </div>
    </article>
  </main>
)

// Export Default SinglePost for front-end
const SinglePost = ({ 
  pageContext: {locale, allPages},
  location: { pathname },
  data: { works, allPosts, tagsGroup } }) => {
  const thisEdge = allPosts.edges.find(edge => edge.node.id === works.id)
  return (
    <Layout
      title={works.frontmatter.title || false}
      path={pathname}
    >
      <SinglePostTemplate
        {...works}
        {...works.frontmatter}
        body={works.html}
        nextPostURL={_.get(thisEdge, 'next.fields.slug')}
        prevPostURL={_.get(thisEdge, 'previous.fields.slug')}
      />
    </Layout>
  )
}

export default SinglePost

export const pageQuery = graphql`
  query SinglePost($locale: String!, $id: String!) {
    works: markdownRemark(frontmatter: {locale: {eq: $locale}}, id: {eq: $id}) {
      html
      id
      frontmatter {
        title
        template
        subtitle
        thumbNail
        model
        date(formatString: "MMMM Do, YYYY")
        category
        gumroad
        artstation
        locale
        tags
      }
    }
    allPosts: allMarkdownRemark(
      filter: {fields: {contentType: {eq: "works"}, locale: {eq: $locale}}}
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
            locale
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter {
            title
            locale
          }
        }
      }
    }
  }
`
