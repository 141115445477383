import React from 'react'
import * as BABYLON from 'babylonjs'
import 'babylonjs-loaders';
import {isMobile} from 'react-device-detect'
import _ from 'lodash'

import './CanvasComponent.css'

export default class Accordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      canvasWidth: null,
      canvasHeight: null
    }
    this.babylonCanvas = null
    this.scene = null
    this.engine = null
    this.currentScene = null
    this.currentPluginName = null
    this.assetsManager = null
    this.canvasSizes = null
  }
  refCallback = element => {
    if (element) {    
      this.babylonCanvas = element;
      this.canvasSizes = element.getBoundingClientRect();
    }
  }
  componentDidMount(){
    if(!isMobile && this.babylonCanvas !== null) {
      if (BABYLON.Engine.isSupported()) {
        this.engine = new BABYLON.Engine(this.babylonCanvas, true, { premultipliedAlpha: false, preserveDrawingBuffer: true });
        this.engine.loadingUIBackgroundColor = "#2A2342";
        this.engine.hideLoadingUI();

        BABYLON.Animation.AllowMatricesInterpolation = true;

        BABYLON.SceneLoader.OnPluginActivatedObservable.add((plugin) =>{
          this.currentPluginName = plugin.name;
          if (this.currentPluginName === "gltf" || this.currentPluginName === 'glb') {
            plugin.onValidatedObservable.add((results) =>{
              if (results.issues.numErrors > 0) {
                 console.log('plugin issues:',results)
              }
            });
          }
        });

        this.scene = new BABYLON.Scene(this.engine)
        this.scene.clearColor = new BABYLON.Color3( .1, .1, .15);
        this.scene.skipFrustumClipping = true;
        this.scene.detachControl();

        var rootUrl = BABYLON.Tools.GetFolderPath(this.props.model);
        var fileName = BABYLON.Tools.GetFilename(this.props.model);

        BABYLON.SceneLoader.LoadAsync(rootUrl, fileName, this.engine).then((scene) =>{
          if (this.scene) {
            this.scene.dispose();
          }

          this.engine.clearInternalTexturesCache();

          this.scene = scene;

          // Attach camera to canvas inputs
          this.scene.createDefaultCamera(true);

          if(_.includes(fileName, 'sheik')){
            this.scene.fogDensity = 0.01
            this.scene.activeCamera = this.scene.cameras[0]
            this.scene.activeCamera.target = this.scene.meshes[0] 
            this.scene.meshes[1].alwaysSelectAsActiveMesh=true
            let rotation= new BABYLON.Vector3(0,1.5,0)
            this.scene.activeCamera.rotation = rotation
          }else{
            if (this.currentPluginName === "gltf") {
              this.scene.activeCamera.alpha += Math.PI;
            }
            //Enable camera's behaviors
            this.scene.activeCamera.useFramingBehavior = true;
            var framingBehavior = this.scene.activeCamera.getBehaviorByName("Framing");
            framingBehavior.framingTime = 0;
            framingBehavior.elevationReturnTime = -1;

            if (this.scene.meshes.length) {
              var worldExtends = this.scene.getWorldExtends();
              // var worldExtends = this.scene.meshes[100].getBoundingInfo().boundingBox;            
              this.scene.activeCamera.lowerRadiusLimit = null;           
              framingBehavior.zoomOnBoundingInfo(worldExtends.min, worldExtends.max);

              if(_.includes(fileName, 'smuggler')){
                let position = new BABYLON.Vector3(0.5,0.1,0)
                this.scene.activeCamera.target = this.scene.meshes[0]
                this.scene.activeCamera.fov = 1
                this.scene.activeCamera.position = position
              }else if(_.includes(fileName, 'samurai')){
                this.scene.activeCamera.target = this.scene.meshes[0]
                this.scene.activeCamera.alpha += Math.PI;
                this.scene.activeCamera.fov = 1.1
              }
            }

          }

          this.scene.activeCamera.lowerbetalimit = 0.1;
          this.scene.activeCamera.upperBetaLimit = (Math.PI / 2) * 0.9;

      this.scene.activeCamera.pinchPrecision = 200 / this.scene.activeCamera.radius;
      this.scene.activeCamera.upperRadiusLimit = 5 * this.scene.activeCamera.radius;

      this.scene.activeCamera.wheelDeltaPercentage = 0.01;
      this.scene.activeCamera.pinchDeltaPercentage = 0.01;

      this.scene.activeCamera.attachControl(this.babylonCanvas);

      var helper = this.scene.createDefaultEnvironment({
            enableGroundShadow: true
      });
        helper.setMainColor(BABYLON.Color3.Gray());

      var light = new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(0, 1, 0), this.scene);
          light.specular = BABYLON.Color3.Black();

      var light2 = new BABYLON.DirectionalLight("dir01", new BABYLON.Vector3(0, -0.5, -1.0), this.scene);
          light2.position = new BABYLON.Vector3(0, 5, 5);

      // Shadows
      var shadowGenerator = new BABYLON.ShadowGenerator(1024, light2);
          shadowGenerator.useBlurExponentialShadowMap = true;
          shadowGenerator.blurKernel = 32;
          shadowGenerator.forceBackFacesOnly = true;
          shadowGenerator.bias = 0.01;
          shadowGenerator.frustumEdgeFalloff = 0.5;

      shadowGenerator.addShadowCaster(this.scene.meshes[0], true);

      this.scene.whenReadyAsync().then(() =>{
        this.engine.runRenderLoop(() =>{
          this.scene.render();
        });
      });
    }).catch((reason) =>{
      this.sceneError('sceneloaderror:', reason.message || reason);
    });
      }
    } 
    window.addEventListener('resize', this.onResizeWindow)
  }
  componentWillUnmount () {
    window.removeEventListener('resize', this.onResizeWindow);
    this.engine.hideLoadingUI();
  }
  sceneError = (message) =>{
    console.log('Babylon Error:',message)
  };
  onResizeWindow = () => {
    if (this.engine) {
      this.engine.resize()
    }
  }
  render() {
    return (
      <div className="canvasContainer">
        <img src={this.props.poster} alt={`3D Model of ${this.props.title}`} className="posterImg" />
        <canvas ref={this.refCallback}></canvas>
        <p className="posterCaption">Loading a 3D Model of {this.props.title}</p>
        <div className="canvasCaption">
          <span>Realtime Render of the 3D Model from {this.props.title}</span> 
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M13.897,4.645c-0.231,0-0.44-0.163-0.488-0.399c-0.056-0.271,0.118-0.535,0.389-0.59c1.885-0.388,2.495-0.958,2.495-1.174 c0-0.529-2.278-1.5-6-1.5s-6,0.971-6,1.5c0,0.216,0.61,0.787,2.495,1.174c0.271,0.056,0.444,0.32,0.389,0.59 c-0.055,0.272-0.323,0.443-0.59,0.389C4.463,4.198,3.293,3.432,3.293,2.481c0-3.282,14-3.282,14,0c0,0.952-1.17,1.717-3.294,2.154 C13.964,4.641,13.931,4.645,13.897,4.645z"/>
            <path d="M5.293,5.981c-0.132,0-0.263-0.052-0.361-0.154C4.741,5.627,4.748,5.311,4.947,5.12l1.188-1.138L5.806,2.596 C5.743,2.328,5.909,2.058,6.177,1.995c0.269-0.066,0.537,0.102,0.602,0.371L7.173,4.03c0.041,0.172-0.013,0.354-0.141,0.476 L5.638,5.842C5.542,5.935,5.417,5.981,5.293,5.981z"/>
            <path d="M15.793,23.981H13.45c-1.202,0-2.333-0.468-3.183-1.318l-7.121-7.122c-0.585-0.585-0.585-1.536,0-2.121 c0.913-0.912,2.379-1.015,3.409-0.242l1.737,1.303v-8.5c0-1.103,0.897-2,2-2s2,0.897,2,2v3.269c0.294-0.171,0.636-0.269,1-0.269 c0.871,0,1.614,0.56,1.888,1.338c0.318-0.213,0.701-0.338,1.112-0.338c0.871,0,1.614,0.56,1.888,1.338 c0.318-0.213,0.701-0.338,1.112-0.338c1.103,0,2,0.897,2,2v5.5C21.293,21.514,18.825,23.981,15.793,23.981z M4.993,13.659 c-0.416,0-0.83,0.159-1.14,0.468c-0.195,0.195-0.195,0.512,0,0.707l7.121,7.122c0.661,0.661,1.54,1.025,2.476,1.025h2.343 c2.481,0,4.5-2.019,4.5-4.5v-5.5c0-0.551-0.448-1-1-1s-1,0.449-1,1v0.5c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5v-1.5 c0-0.551-0.448-1-1-1s-1,0.449-1,1v1.5c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5v-2.5c0-0.551-0.448-1-1-1s-1,0.449-1,1v2.5 c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5v-7.5c0-0.551-0.448-1-1-1s-1,0.449-1,1v9.5c0,0.189-0.107,0.362-0.276,0.447 c-0.169,0.084-0.372,0.067-0.523-0.047l-2.537-1.903C5.671,13.764,5.331,13.659,4.993,13.659z"/>
          </svg>
        </div>
      </div>
    )
  }
}