import React, {Fragment} from 'react'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server'
import Image from './Image'
import VideoComponent from './VideoComponent'
import {X, Play, Pause, Volume2, VolumeX, Minimize2, Maximize} from 'react-feather'

import './Content.css'

export default class Content extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPopup: false,
      playing: false,
      mute: true,
      image:{
        src: '',
        alt: '',
        title: ''
      }
    }
  } 
  withContentMedia = (source) => {
    const images = source.match(/<img(.*?)\\?>/gim)
    const videos = source.match(/<\s*video[^>]*>(.*?)<\s*\/\s*video>/gim)

    source = this.createMediaElement('image', images, source)
    source = this.createMediaElement('video', videos, source)
    
    return source
  }
  createMediaElement = (type, media, source) =>{
    if (media !== null){
      for (let i in media) {                 
        switch(type){
          case 'image':
            const imgsrc = /src="(.*?)"/g.exec(media[i]) 
            let alt = /alt="(.*?)"/g.exec(media[i])
            source = source.replace(media[i],
              ReactDOMServer.renderToString(
                <div className="beforeImg"><Image resolutions="medium" className="Content--Image" lazy={false} src={imgsrc ? imgsrc[1] : null} alt={alt ? alt[1] : null} /></div>
              )
            )
            break;
          case 'video':
            const videosrc = /src=(.+?)\./g.exec(media[i]) 
            const ending = /[.](.*?)\w{3}/g.exec(media[i])
            source = source.replace(media[i],
              ReactDOMServer.renderToString(
                <VideoComponent className="Content--Video" src={videosrc ? (videosrc[1]+ending[0]) : null} />
              )
            )
            break;
          default:
            console.log("Sorry, something went wrong");
        }
      }
    };
    return source
  }
  togglePopup = (e)=> {
    this.setState({showPopup: !this.state.showPopup});
  }
  togglePlay = (v, e) =>{   
    switch(e.target.parentNode.classList[1]){
      case 'play':
        const method = v.paused || v.ended ? 'play' : 'pause';
        v[method]();
        this.setState({playing: !this.state.playing})
        this.updateButton(e.target.parentNode);
        break;
      case 'volume':
        // v.muted = this.state.mute ? v.muted : !v.muted;
        // this.setState({mute: !this.state.mute})
        // this.updateButton(e.target.parentNode);
        console.log('nope...no sound :)')
        break;
      default:
        //console.log('other');
        break;
    }
  }
  updateButton = (v)=>{
    let newEl = null;
    switch (v.classList[1]) {
      case 'play':
      case 'pause':
        this.state.playing ? newEl = <Pause /> : newEl = <Play />; 
        break;
      case 'volume':
        this.state.mute ? newEl = <VolumeX /> : newEl = <Volume2 />;
        break;
      default:
        console.log('no');
        break;
    }
    v.innerHTML = ReactDOMServer.renderToString(newEl);
  }

  handleRangeUpdate = (video, range) => {
    video.volume= range.value;
  }

  handleProgress = (video, progressBar, progress) => {
    if (!progress.getAttribute('max')) progress.setAttribute('max', video.duration);
    progress.value = video.currentTime;
    const percent = Math.floor((video.currentTime / video.duration) * 100);
    progressBar.style.flexBasis = `${percent}%`;
  }
  scrub = (e, video, progress) =>{
    const scrubTime = (e.offsetX / progress.offsetWidth) * video.duration;
    video.currentTime = scrubTime;
  }

  skip = (video, e) =>{
    if(e.currentTarget.hasAttribute('title')){
      let st = e.currentTarget.getAttribute('title')
      let time = parseFloat(st.replace('s', ''))
      video.currentTime += time;
    } 
  }
  seekFrames = (video,e ) =>{
    let st = '';
    let nr_of_frames = 1
    if(e.currentTarget.hasAttribute('title')){
      st = e.currentTarget.getAttribute('title')
      nr_of_frames = parseInt(st.replace('f', ''))
    }

    if (!video.paused) {video.pause();}
  
    var currentFrames = video.currentTime * 24;
  
    var newPos = (currentFrames + nr_of_frames) / 24;
    newPos = newPos + 0.00001; // FIXES A SAFARI SEEK ISSUE. myVdieo.currentTime = 0.04 would give SMPTE 00:00:00:00 wheras it should give 00:00:00:01
  
    video.currentTime = newPos; // TELL THE PLAYER TO GO HERE
  }

  readableTime = (t) =>{
    let theMinutes = "0" + Math.floor(t / 60); // Divide seconds to get minutes, add leading zero
    let theSeconds = "0" + parseInt(t % 60); // Get remainder seconds
    let theTime = theMinutes.slice(-2) + ":" + theSeconds.slice(-2); // Slice to two spots to remove excess zeros
    return theTime;
  }

  isFullScreen = () => {
   return !!(document.fullScreen || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || document.fullscreenElement);
  }  

  handleFullScreen = (videoContainer, event) =>{
    event.stopPropagation();
    let newEl = null;
    if (this.isFullScreen()) {
      newEl =<Maximize className="fullscreen-icon" />
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();
      this.setFullscreenData(videoContainer, false);
      event.currentTarget.innerHTML = ReactDOMServer.renderToString(newEl);
   }else {
      newEl = <Minimize2 className="fullscreen-icon" />;
      if (videoContainer.requestFullscreen) videoContainer.requestFullscreen();
      else if (videoContainer.mozRequestFullScreen) videoContainer.mozRequestFullScreen();
      else if (videoContainer.webkitRequestFullScreen) videoContainer.webkitRequestFullScreen();
      else if (videoContainer.msRequestFullscreen) videoContainer.msRequestFullscreen();
      this.setFullscreenData(videoContainer, true);
      event.currentTarget.innerHTML = ReactDOMServer.renderToString(newEl);
    }
  }

  setFullscreenData = (videoContainer, state) => {
    videoContainer.setAttribute('data-fullscreen', !!state);
  }

  componentDidMount(){
    let that = this;
    var fullScreenEnabled = !!(document.fullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled || document.webkitSupportsFullscreen || document.webkitFullscreenEnabled );
    const node = ReactDOM.findDOMNode(this);
    if (node instanceof HTMLElement) {
      const childs = node.querySelectorAll('img');
      const videos = node.querySelectorAll('.player');
      childs.forEach(child => child.addEventListener('click', (e)=>{
        const src = e.target.getAttribute("src"),
        alt = e.target.getAttribute("alt")
        that.setState({image: {src: src, alt: alt}})
        that.togglePopup(e.target)
      }));
      videos.forEach(video => {
        const viewer = video.querySelector('.viewer');
        const progress = video.querySelector('.progress');
        const progressBar = video.querySelector('.progress__filled');
        const volumeBtn = video.querySelector('.player__button.volume')
        const volume = video.querySelector('.player__slider'); 
        const timeCurrent = video.querySelector('.time-current');
        const timeDuration = video.querySelector('.time-duration');
        const skipButtons = video.querySelectorAll('.skip'); 
        const frameButtons = video.querySelectorAll('.frame');
        const fullscreen = video.querySelector('.fullscreen')
        let mousedown = false;

        if (!fullScreenEnabled) {
         fullscreen.style.display = 'none';
        }

        viewer.addEventListener('loadedmetadata', () =>{
         timeCurrent.innerHTML = this.readableTime(viewer.currentTime)
         timeDuration.innerHTML = this.readableTime(viewer.duration);
         if(viewer.audioTracks.length ===0) volumeBtn.style.display = 'none'
        })

        video.addEventListener('click', (e)=>{
          this.togglePlay(viewer,e);
        })
        viewer.addEventListener('timeupdate', () =>{
          timeCurrent.innerHTML = this.readableTime(viewer.currentTime);
          this.handleProgress(viewer, progressBar, progress)}
        );

        progress.addEventListener('click', (e) => this.scrub(e, viewer, progress));
        progress.addEventListener('mousemove', (e) => mousedown && this.scrub(e, viewer, progress));
        progress.addEventListener('mousedown', () => mousedown = true);
        progress.addEventListener('mouseup', () => mousedown = false);

        volume.addEventListener('change', () => { this.handleRangeUpdate(viewer, volume)});
        volume.addEventListener('mousemove', () => { this.handleRangeUpdate(viewer, volume)});

        skipButtons.forEach(button => button.addEventListener('click', (e) => this.skip(viewer, e)));
        frameButtons.forEach(button => button.addEventListener('click', (e) => this.seekFrames(viewer, e)));

        fullscreen.addEventListener('click', (e)=>this.handleFullScreen(video, e))

        document.addEventListener('fullscreenchange', (e) =>{
          this.setFullscreenData(video, !!(document.fullScreen || document.fullscreenElement));
        });
        document.addEventListener('webkitfullscreenchange', () => {
          this.setFullscreenData(video, !!document.webkitIsFullScreen);
        });
        document.addEventListener('mozfullscreenchange', () => {
          this.setFullscreenData(video, !!document.mozFullScreen);
        });
        document.addEventListener('msfullscreenchange', () =>{
          this.setFullscreenData(video,!!document.msFullscreenElement);
        });
      })
    }
  }
  render() {
    let source = this.withContentMedia(this.props.source);
 
    return(
      <Fragment>
        <div
          className={`Content`}
          dangerouslySetInnerHTML={{ __html: source }}
        />
        {this.state.showPopup ? (
          <div className="Popup-Overlay">
            <div className="Popup-Background" onClick={this.togglePopup}
            ></div>
            <div className="Popup-Inner">
              <button onClick={this.togglePopup}><X className="Popup-Close"/></button>
              <Image
                resolutions="medium"
                className="Content--Image"
                lazy={false}
                src={this.state.image.src}
                alt={this.state.image.alt}
              />
            </div>
          </div>
        ) : null}
      </Fragment>
    )
  }
}